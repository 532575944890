<template>
  <div class="main-menu" :key="'main-menu-' + currentLanguage">
    <h3 class="label">{{ $t("navigation.main-menu.label") }}</h3>
    <ul v-if="menu && menu.main && menu.main.menu_items" class="menu" v-bind:class="menuVisibilityClass">
      <template v-for="(menuItem, index) in menu.main.menu_items" :key="'l' + index">
        <li class="menu-splitter" v-if="index === 6">
          <a href="#" @click.prevent="toggleMenu" :aria-expanded="isShown ? 'true' : 'false'" tabindex="-1" focusable="false">
            <span v-if="isShown">{{ $t("navigation.less-topics.label") }}</span>
            <span v-else>{{ $t("navigation.more-topics.label") }}</span>
          </a>
        </li>
        <li v-if="menuItem.external === false" :class="visibilityClass(index)">
        <router-link
          :key="'rl' + index"
          v-if="menuItem.external === false"
          :to="'/' + menuItem.path"
          active-class="active"
          :target="menuItem.target"
          :class="menuItem.class"
          :rel="menuItem.rel">
            <span @click="closeNavigation">
              {{ menuItem.title }}
            </span>
        </router-link>
        </li>
        <li class="external" v-else :class="visibilityClass(index)">
          <a :href="menuItem.path" :target="menuItem.target" :class="menuItem.class" :rel="menuItem.rel" tabindex="-1" focusable="false">
            <span v-if="externalIsInternal(menuItem.path)" @click="closeNavigation">
              {{ menuElem.title }}
            </span>
            <span v-else>
              {{ menuElem.title }}
            </span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>

<script>
import { menu } from '@/components/mixins/menuMixin.js'
import { navigation } from '@/components/mixins/navigationMixin.js'
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev, menu, navigation],
  props: {
    menuName: {
      type: String,
      default: 'main'
    }
  },
  data () {
    return {
      menu: {
        data: {}
      },
      isShown: false,
      menuVisibilityClass: ''
    }
  },
  methods: {
    visibilityClass (index) {
      if (index >= 6) {
        return 'hide'
      }
    },
    toggleMenu () {
      this.$parent.destroyScroll()
      this.$parent.createScroll()
      if (!this.isShown) {
        this.isShown = true
        this.menuVisibilityClass = 'show'
      } else {
        this.isShown = false
        this.menuVisibilityClass = ''
      }
    }
  }
}
</script>
