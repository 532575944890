<template>
  <div class="form-select" @focusout="focusOut" @focusin="focusIn">
    <div :class="{opened: opened,__disabled: disabled}" class="select">
      <div @click.prevent="toggle" class="select_label">
        <div class="arrow arrow-top"></div>
        <a href="#" class="label">{{ getLabel(modelValue) }}</a>
        <div class="arrow arrow-bottom"></div>
      </div>
      <div class="select_options_wrapper" :style="selectOptionsWrapperStyles">
        <div class="select_options">
          <div v-for="(option, index) in options" :key="index" :class="{__active: getVal(option) === getVal(modelValue)}" class="select_option">
            <a @click.prevent="change(option)" href="#">{{ getLabel(option) }}</a>
          </div>
        </div>
      </div>
      <div v-if="opened" class="select_background" :style="selectBackgroundStyles"></div>
      <div v-if="opened" @click="toggle" class="select_overlay"></div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import niceScroll from 'jquery.nicescroll'
/* eslint-enable no-unused-vars */
import { dev } from '@/components/mixins/devMixin.js'

export default {
  mixins: [dev],
  props: {
    modelValue: {
      required: true
    },
    options: {
      type: Array,
      required: true
    },
    valueKey: {
      type: String,
      required: false
    },
    labelKey: {
      type: String,
      required: false
    },
    onChange: {
      type: Function,
      required: false
    },
    disabled: {
      type: Boolean
    }
  },
  data () {
    return {
      opened: false,
      focused: false,
      timeouts: []
    }
  },
  mounted: function () {
    this.emitter.on('ESCPressed', this.ESCPressedSelect)
  },
  computed: {
    selectOptionsWrapperStyles: function () {
      const count = this.options.length
      let h = 0
      if (count >= 5) {
        h = 180
      } else {
        h = (count * 40) - 20
      }
      return {
        height: h + 'px'
      }
    },
    selectBackgroundStyles: function () {
      let h = 90
      const count = this.options.length
      if (count >= 5) {
        h = 270
      } else {
        h = h + ((count * 40) - 20)
      }
      return {
        height: h + 'px'
      }
    }
  },
  methods: {
    getVal (opt) {
      return !this.valueKey ? opt : opt[this.valueKey]
    },
    getLabel (opt) {
      return !this.labelKey ? opt : opt[this.labelKey]
    },
    change (opt) {
      this.$emit('update:modelValue', opt)
      this.opened = false
      this.destroyScroll()
      this.$nextTick(function () {
        if (this.onChange !== undefined) {
          this.onChange(this.modelValue)
        }
      })
    },
    toggle () {
      if (this.disabled) {
        return
      }
      this.opened = !this.opened
      if (this.opened && this.options.length > 5) {
        const timeout = setTimeout(() => {
          this.createScroll()
        }, 100)
        this.timeouts.push(timeout)
      } else {
        this.destroyScroll()
      }
    },
    createScroll () {
      $('.form-selects .form-select .select.opened .select_options_wrapper').niceScroll({
        cursorcolor: '#000000',
        autohidemode: false,
        cursorborderradius: 0,
        cursorwidth: 4,
        cursorborder: 'none',
        background: '#e5e5e5',
        grabcursorenabled: true,
        enablemousewheel: true,
        scrollbarid: 'nicescroll-custom-select'
      })
    },
    destroyScroll () {
      $('.form-selects .form-select .select .select_options_wrapper').getNiceScroll().remove()
    },
    ESCPressedSelect () {
      this.closeSelect()
      this.$el.querySelector('.select_label a').focus()
    },
    focusOut () {
      this.focused = false
      const self = this
      const timeout = setTimeout(() => {
        if (!self.focused && self.opened === true) {
          self.closeSelect()
        }
      }, 100)
      this.timeouts.push(timeout)
    },
    focusIn () {
      this.focused = true
    },
    closeSelect () {
      this.opened = false
      this.destroyScroll()
    }
  },
  beforeUnmount () {
    this.destroyScroll()
    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i])
    }
    this.emitter.off('ESCPressed', this.ESCPressedSelect)
  }
}
</script>
